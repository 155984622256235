<template>
    <Toast />
    <Toast position="center" style="min-width:15vw" group="dialogtoast">
        <template #message="slotProps">
            <div class="flex flex-column">
                <div class="text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h5>{{slotProps.message.detail}}</h5>
                </div>
                <div class="grid p-fluid">
                    <div class="col-12">
                        <Button class="p-button-success" label="确定" @click="dialogmsgClose" />
                    </div>
                </div>
            </div>
        </template>
    </Toast>
    <ConfirmDialog></ConfirmDialog>
    <div class="layout-sidebar">
        <div class="layout-menu-container">
            <div>
                <Menubar :model="items">
                </Menubar>
            </div>
            <Tree :value="nodes" @nodeSelect="onNodeSelect" v-model:expandedKeys="expandedKey"
                v-model:selectionKeys="selectedKey" selectionMode="single">
            </Tree>
        </div>
    </div>
    <div class="layout-main-container">
        <div class="layout-main">
            <div class="card">
                <h5>{{listTitle}}</h5>
                <DataTable :value="instoragelist" v-model:filters="filters1" v-model:selection="selectedRow"
                    selectionMode="multiple" :metaKeySelection="false" :filters="filters1" filterDisplay="menu"
                    dataKey="id" :globalFilterFields="['instorageorderno','applydate']" scrollable
                    :scrollHeight="sHeight" :tableStyle="styleHeight" responsiveLayout="scroll" :loading="loading"
                    :lazy="true" :paginator="true" :rows="pagesize" :virtualScrollerOptions="vsOptionFun()"
                    :rowsPerPageOptions="[50, 100, 200, 500]" :totalRecords="totalRecords" :first="first"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="共{totalRecords}记录 当前页{first} 到 {last}" class="p-mt-3"
                    @page="listPage($event)" @sort="onSort($event)">
                    <template #header>
                        <div class="flex justify-content-between flex-column sm:flex-row">
                            <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined mb-2"
                                @click="clearFilter1()" />
                            <span class="p-input-icon-left mb-2" style="width:25%">
                                <i class="pi pi-search" />
                                <InputText v-model="filters1['global'].value" placeholder="输入入库单号模糊查询"
                                    style="width:100%" @keyup.enter="customFilter();" />
                            </span>
                        </div>
                    </template>
                    <Column selectionMode="multiple" frozen bodyStyle="width:3em;flex:0;"
                        headerStyle="width:3em;flex:0;"></Column>
                    <Column v-for="col of listcolumns" :field="col.field" :bodyStyle="col.bodyStyle"
                        :headerStyle="col.headerStyle" :showFilterOperator="col.showFilterOperator" :header="col.header"
                        :key="col.field" :showFilterMenu="col.showFilterMenu" :showFilterMatchModes="col.showFilterMenu"
                        :filterMatchModeOptions="col.filterMatchModeOptions" :sortable="col.sortable"
                        :frozen="col.frozen">
                        <template v-if="col.islink || col.oneline || col.isimage" #body="slotProps">
                            <Button v-if="col.islink" :label="slotProps.data.instorageorderno"
                                @click="openInStorage(slotProps.data)" class="p-button-link" />
                            <label v-if="col.oneline" class="oneline"
                                v-tooltip.top="{ value: slotProps.data[col.field], disabled: false }">{{slotProps.data[col.field]}}</label>
                            <Image v-if="col.isimage" :src="getFilePatch(slotProps.data[col.field])" width="50"
                                preview />
                        </template>
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="输入筛选" />
                        </template>
                        <template #filterclear="{filterCallback}">
                            <Button type="button" label="清除" @click="filterCallback()"
                                class="p-button-secondary"></Button>
                        </template>
                        <template #filterapply="{filterCallback}">
                            <Button type="button" label="查询" @click="filterCallback();customFilter();"
                                class="p-button-success"></Button>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
        <AppFooter />
    </div>
    <Dialog :header="recordsubject" id="mainDialog" class="p-dialog-maximized" @maximize="selfmaximize"
        @unmaximize="selfmaximize" @hide="dialogClose" v-model:visible="displayMaximizable" :style="{width: '70vw'}"
        :maximizable="true" :modal="true">
        <p class="p-m-0">
            <NewUniversalPage ref="universalpage" :closeButLoadingMethod="closeButLoading" :currentRow="currentRow"
                :freshListMethod="freshList" :closeParentMehod="closeMaximizable" :loadDataAfterMethod="loadDataAfter"
                :childNewDataConfig="childNewDataConfig" :importAddQueryConditionMethod="importAddQueryCondition"
                :dropOtherConfig="dropOtherConfig" :importAddColsMethod="importAddCols"
                :childTableFooter="childTableFooter" :pageJsonConfig="pageJsonConfig"
                :CellEditCompleteMethod="cellEditComplete" :childaloneshowConfig="childaloneshowConfig"
                :CellEditCompleteExpandeMethod="cellEditCompleteExpande" :childCreateBeforeMethod="childCreateBefore"
                :childExpandePageConfig="childExpandeConfig" :isReadOnly="readOnly" />
        </p>
        <template #footer>
            <div class="flex p-3 card">
                <Button :label="currentState" icon="pi pi-sitemap" class="p-button-raised p-button-text mr-2"
                    @click="openLeftPosition" />
                <Button label="关闭" icon="pi pi-power-off" class="ml-auto" @click="closeMaximizable" />
                <Button v-if="!readOnly" label="保存" icon="pi pi-check" @click="saveData" :loading="butLoading"
                    autofocus />
                <Button v-if="!readOnly" label="提交" icon="pi pi-check" @click="submitData" :loading="butLoading"
                    autofocus />
            </div>
        </template>
    </Dialog>
    <Dialog header="保存查询方案" v-model:visible="displayQueryPlan" :style="{width: '25vw'}" :modal="true">
        <div class="card">
            <div class="grid p-fluid mt-3">
                <div class="field col-12 md:col-12">
                    <span class="p-float-label">
                        <InputText type="text" id="queryPlanName" v-model="queryPlanObj.name" />
                        <label for="queryPlanName">查询方案名称</label>
                    </span>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="取消" icon="pi pi-power-off" :loading="butLoading" @click="closeQueryPlan"
                class="p-button-text" />
            <Button label="保存" icon="pi pi-check" :loading="butLoading" @click="saveQueryPlan" autofocus />
        </template>
    </Dialog>
    <Dialog header="流程信息" v-model:visible="displayPosition" :style="{width: '50vw'}" :position="position"
        :maximizable="true" :modal="true">
        <FlowHistory ref="flowhistory" :flowHistoryData="flowhistoryJson" :title="recordsubject" />
        <template #footer>
            <Button label="关闭" icon="pi pi-power-off" @click="closePosition" autofocus />
        </template>
    </Dialog>
    <Dialog header="选择出运单" v-model:visible="showShipmentDialog" :style="{width: '70vw'}" :maximizable="true"
        :modal="true">
        <p class="p-m-0">
            <DataTable :value="shipments" v-model:filters="shipMentFilters1" v-model:selection="selectedShipmentRow"
                :metaKeySelection="false" :filters="shipMentFilters1" filterDisplay="menu" dataKey="id"
                selectionMode="single" :globalFilterFields="['shipmentno','signingdate']" scrollable
                scrollHeight="350px" responsiveLayout="scroll" :loading="loading" :paginator="true" rows="50"
                :totalRecords="totalShipmentRecords" :first="firstShipment"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                currentPageReportTemplate="共{totalRecords}记录 当前页{first} 到 {last}" class="p-mt-3"
                @page="listShipmentPage($event)">
                <template #header>
                    <div class="flex justify-content-between flex-column sm:flex-row">
                        <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined mb-2"
                            @click="clearShipmentFilter1()" />
                        <span class="p-input-icon-left mb-2">
                            <i class="pi pi-search" />
                            <InputText v-model="filters1['global'].value" placeholder="输入关键字查询" style="width: 100%" />
                        </span>
                    </div>
                </template>
                <Column selectionMode="single" frozen bodyStyle="width:3em;flex:0;" headerStyle="width:3em;flex:0;">
                </Column>
                <Column field="shipmentno" bodyStyle="min-width:170px;left:0px" headerStyle="min-width:170px;left:0px"
                    :showFilterOperator="false" header="入库编号" frozen :filterMatchModeOptions="matchModes"
                    :sortable="true">
                    <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="输入筛选" />
                    </template>
                    <template #filterclear="{filterCallback}">
                        <Button type="button" label="清除" @click="filterCallback()" class="p-button-secondary"></Button>
                    </template>
                    <template #filterapply="{filterCallback}">
                        <Button type="button" label="查询" @click="filterCallback();customShipmentFilter();"
                            class="p-button-success"></Button>
                    </template>
                </Column>
                <Column field="signingdate" style="min-width:160px" :showFilterOperator="false" header="签约日期"
                    :filterMatchModeOptions="timeMatchModes" :sortable="true">
                    <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="输入筛选" />
                    </template>
                    <template #filterclear="{filterCallback}">
                        <Button type="button" label="清除" @click="filterCallback()" class="p-button-secondary"></Button>
                    </template>
                    <template #filterapply="{filterCallback}">
                        <Button type="button" label="查询" @click="filterCallback();customShipmentFilter();"
                            class="p-button-success"></Button>
                    </template>
                </Column>
                <Column field="shippingwayname" :showFilterOperator="false" :filterMatchModeOptions="matchModes"
                    style="min-width:160px" header="出货方式" :sortable="true">
                    <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="输入筛选" />
                    </template>
                    <template #filterclear="{filterCallback}">
                        <Button type="button" label="清除" @click="filterCallback()" class="p-button-secondary"></Button>
                    </template>
                    <template #filterapply="{filterCallback}">
                        <Button type="button" label="查询" @click="filterCallback();customShipmentFilter();"
                            class="p-button-success"></Button>
                    </template>
                </Column>
                <Column field="purchasecurrencyname" :showFilterOperator="false" :filterMatchModeOptions="numMatchModes"
                    style="min-width:160px" header="采购币种" :sortable="true">
                    <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="输入筛选" />
                    </template>
                    <template #filterclear="{filterCallback}">
                        <Button type="button" label="清除" @click="filterCallback()" class="p-button-secondary"></Button>
                    </template>
                    <template #filterapply="{filterCallback}">
                        <Button type="button" label="查询" @click="filterCallback();customShipmentFilter();"
                            class="p-button-success"></Button>
                    </template>
                </Column>
                <Column field="purchaseexchangerate" :showFilterOperator="false" :filterMatchModeOptions="matchModes"
                    style="min-width:180px" header="采购汇率" :sortable="true">
                    <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="输入筛选" />
                    </template>
                    <template #filterclear="{filterCallback}">
                        <Button type="button" label="清除" @click="filterCallback()" class="p-button-secondary"></Button>
                    </template>
                    <template #filterapply="{filterCallback}">
                        <Button type="button" label="查询" @click="filterCallback();customShipmentFilter();"
                            class="p-button-success"></Button>
                    </template>
                </Column>
                <Column field="fobcurrencyname" :showFilterOperator="false" :filterMatchModeOptions="matchModes"
                    style="min-width:160px" header="FOB币种" :sortable="true">
                    <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="输入筛选" />
                    </template>
                    <template #filterclear="{filterCallback}">
                        <Button type="button" label="清除" @click="filterCallback()" class="p-button-secondary"></Button>
                    </template>
                    <template #filterapply="{filterCallback}">
                        <Button type="button" label="查询" @click="filterCallback();customShipmentFilter();"
                            class="p-button-success"></Button>
                    </template>
                </Column>
                <Column field="fobexchangerate" :showFilterOperator="false" style="min-width:160px" header="FOB汇率"
                    :sortable="true" :filterMatchModeOptions="numMatchModes">
                    <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="输入筛选" />
                    </template>
                    <template #filterclear="{filterCallback}">
                        <Button type="button" label="清除" @click="filterCallback()" class="p-button-secondary"></Button>
                    </template>
                    <template #filterapply="{filterCallback}">
                        <Button type="button" label="查询" @click="filterCallback();customShipmentFilter();"
                            class="p-button-success"></Button>
                    </template>
                </Column>
                <Column field="rateprofit" :showFilterOperator="false" :filterMatchModeOptions="numMatchModes"
                    style="min-width:160px" header="利润率" :sortable="true">
                    <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="输入筛选" />
                    </template>
                    <template #filterclear="{filterCallback}">
                        <Button type="button" label="清除" @click="filterCallback()" class="p-button-secondary"></Button>
                    </template>
                    <template #filterapply="{filterCallback}">
                        <Button type="button" label="查询" @click="filterCallback();customShipmentFilter();"
                            class="p-button-success"></Button>
                    </template>
                </Column>
                <Column field="operator" :showFilterOperator="false" :filterMatchModeOptions="matchModes"
                    style="min-width:160px" header="操作员" :sortable="true">
                    <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="输入筛选" />
                    </template>
                    <template #filterclear="{filterCallback}">
                        <Button type="button" label="清除" @click="filterCallback()" class="p-button-secondary"></Button>
                    </template>
                    <template #filterapply="{filterCallback}">
                        <Button type="button" label="查询" @click="filterCallback();customShipmentFilter();"
                            class="p-button-success"></Button>
                    </template>
                </Column>
                <Column field="storename" :showFilterOperator="false" :filterMatchModeOptions="matchModes"
                    style="min-width:200px" header="平台" :sortable="true">
                    <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="输入筛选" />
                    </template>
                    <template #filterclear="{filterCallback}">
                        <Button type="button" label="清除" @click="filterCallback()" class="p-button-secondary"></Button>
                    </template>
                    <template #filterapply="{filterCallback}">
                        <Button type="button" label="查询" @click="filterCallback();customShipmentFilter();"
                            class="p-button-success"></Button>
                    </template>
                </Column>
                <Column field="instoragename" :showFilterOperator="false" :filterMatchModeOptions="matchModes"
                    style="min-width:160px" header="是否入库" :sortable="true">
                    <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="输入筛选" />
                    </template>
                    <template #filterclear="{filterCallback}">
                        <Button type="button" label="清除" @click="filterCallback()" class="p-button-secondary"></Button>
                    </template>
                    <template #filterapply="{filterCallback}">
                        <Button type="button" label="查询" @click="filterCallback();customShipmentFilter();"
                            class="p-button-success"></Button>
                    </template>
                </Column>
            </DataTable>
        </p>
        <template #footer>
            <div class="flex p-3 card">
                <Button label="关闭" icon="pi pi-power-off" class="ml-auto" @click="closeShipmentData" />
                <Button label="导入" icon="pi pi-check" @click="importShipmentData" :loading="butLoading" autofocus />
            </div>
        </template>
    </Dialog>
</template>

<script>
    import {
        ref,
    } from 'vue';
    import {
        FilterMatchMode,
        FilterOperator
    } from 'primevue/api';
    import {
        useConfirm
    } from "primevue/useconfirm";
    import ConfigService from '../../../service/ConfigService';
    import CommonJs from '../../../js/Common.js';
    import MessageTip from '../../../components/Message';
    import AppFooter from '../../../AppFooter.vue';
    import FlowHistory from '../../../components/FlowHistory.vue';
    import NewUniversalPage from '../../../components/NewUniversalPage.vue';
    import requests from '../../../service/newaxios';
    import * as Base64 from 'js-base64';
    // import putinstoragePageJson from "@/data/putinstorageConfig.json";
    export default {
        setup() {
            const confirm = useConfirm();
            const currentMenuId = '706';
            const listcolumns = ref();
            const usermoduleauth = [];
            return {
                confirm,
                currentMenuId,
                listcolumns,
                usermoduleauth
            };
        },
        data() {
            return {
                matchModes: [{
                        label: '以字符开始',
                        value: FilterMatchMode.STARTS_WITH
                    },
                    {
                        label: '以字符结束',
                        value: FilterMatchMode.ENDS_WITH
                    },
                    {
                        label: '包含字符',
                        value: FilterMatchMode.CONTAINS
                    },
                ],
                timeMatchModes: [{
                        label: '小于',
                        value: FilterMatchMode.DATE_BEFORE
                    },
                    {
                        label: '大于',
                        value: FilterMatchMode.DATE_AFTER
                    },
                ],
                numMatchModes: [{
                        label: '大于等于',
                        value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                    },
                    {
                        label: '小于等于',
                        value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                    },
                ],
                pageJsonConfig: {},
                butLoading: false,
                recordsubject: '',
                readOnly: false,
                displayMaximizable: false,
                displayQueryPlan: false,
                displayPosition: false,
                showShipmentDialog: false,
                position: 'bottomleft',
                currentState: '流程信息',
                filters1: {
                    'global': {
                        value: null,
                        matchMode: FilterMatchMode.CONTAINS
                    }
                },
                shipMentFilters1: null,
                flowId: '',
                startStateId: '',
                currentRow: {
                    bindpage: 'putinstorage',
                    id: '-1',
                    workflowid: '',
                    flowguid: 'PutInStorage',
                    boxid: '',
                    stateid: '',
                    flowid: ''
                },
                currencyDict: [],
                childNewDataConfig: {
                    products: false,
                    files: true,
                    cost: true,
                },
                flowhistoryJson: null,
                selectedRow: ref(),
                selectedShipmentRow: ref(),
                sHeight: '600px',
                styleHeight: '',
                minHeight: 'min-height:600px',
                totalRecords: -1,
                first: 0,
                pagesize: 50,
                totalShipmentRecords: 0,
                firstShipment: 0,
                listTitle: '草稿列表',
                selectedKey: {
                    706: true
                },
                expandedKey: null,
                childExpandeConfig: {},
                instoragelist: [],
                shipments: [],
                loading: true,
                nodes: [],
                items: [{
                    label: '入库操作',
                    icon: 'pi pi-fw pi-users',
                    items: [{
                            label: '新建入库',
                            icon: 'pi pi-fw pi-user-plus',
                            command: () => {
                                this.createInStorage();
                            }
                        },
                        {
                            separator: true
                        },
                        {
                            label: '删除',
                            icon: 'pi pi-fw pi-trash',
                            command: () => {
                                this.delInStorage();
                            }
                        }
                    ]
                }],
                queryPlanObj: {
                    name: '',
                    id: '-1',
                    parentid: this.currentMenuId,
                    queryconfig: '',
                },
                dropOtherConfig: {
                    instoragecurrency: 'instorageexchangerate',
                    instoragecurrencyfun: (childelements, v_val, v_dictsource) => {
                        this.inStorageCurrencyChange(childelements, v_val, v_dictsource);
                    },
                    cost: {
                        currency: 'exchangerate'
                    },
                    sharingcostcurrency: 'sharingcostexchangerate',
                },
                childaloneshowConfig: {
                    cost: '1'
                },
                childTableFooter: {
                    cost: {
                        costtype: '合计',
                        costamount: 0,
                        invoiceamount: 0,
                    },
                    products: {
                        platformsku: '合计',
                        expectedinstoragequantity: 0,
                        actualinstoragequantity: 0,
                        difference: 0,
                        allvolume: 0,
                        sharingcost: 0,
                        allweight: 0,
                    }
                },
            }
        },
        configService: null,
        created() {
            this.pagesize = CommonJs.GetDefaultPageSize(this);
            this.configService = new ConfigService();
            MessageTip.initMsg();
            this.init();
        },
        methods: {
            init() {
                this.configService.getputinstorage(sessionStorage.getItem("appkey")).then(data => {
                    console.log(data);
                    this.listcolumns = data.list;
                    this.pageJsonConfig = data.page;
                    this.childExpandeConfig = data.page['region_3']['left']['element'][0][
                        'childExpandeConfig'
                    ];
                    this.initFilters1();
                    this.loadMenuTree();
                    this.loadFlowInfo();
                    CommonJs.loadDictFromCache('Currency', 'dictionaryCurrency', this.currencyDict, this
                        .setloadDictFromCache);
                    this.loadUserAuth();
                    this.sHeight = (window.innerHeight - 280) + "px";
                    this.styleHeight = 'height:' + (window.innerHeight - 300) + 'px;';
                    this.minHeight = "height:" + (window.innerHeight - 132) + "px";
                });
            },
            vsOptionFun() {
                return CommonJs.vsOptionFun(this.pagesize, this.totalRecords);
            },
            setloadDictFromCache(v_currencyDict) {
                this.currencyDict = v_currencyDict;
            },
            initFilters1() {
                CommonJs.initFilters(this.filters1, this.listcolumns, this.setinitFilters);
            },
            setinitFilters(v_filters1) {
                this.filters1 = v_filters1;
            },
            clearFilter1() {
                this.initFilters1();
                this.loadData(1);
            },
            setlistTitleFun(val) {
                this.listTitle = val;
            },
            initShipmentFilters1() {
                this.shipMentFilters1 = {
                    'global': {
                        value: null,
                        matchMode: FilterMatchMode.CONTAINS
                    },
                    'shipmentno': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.CONTAINS
                        }]
                    },
                    'signingdate': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.DATE_BEFORE
                        }, {
                            value: null,
                            matchMode: FilterMatchMode.DATE_AFTER
                        }]
                    },
                    'shippingwayname': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.CONTAINS
                        }]
                    },
                    'purchasecurrencyname': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.CONTAINS
                        }]
                    },
                    'purchaseexchangerate': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                        }, {
                            value: null,
                            matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                        }]
                    },
                    'fobcurrencyname': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.CONTAINS
                        }]
                    },
                    'fobexchangerate': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                        }, {
                            value: null,
                            matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                        }]
                    },
                    'rateprofit': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                        }, {
                            value: null,
                            matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                        }]
                    },
                    'storename': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.CONTAINS
                        }]
                    },
                    'approvestatusname': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.CONTAINS
                        }]
                    },
                    'instoragename': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.CONTAINS
                        }]
                    },
                    'operator': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.CONTAINS
                        }]
                    },
                }
            },
            clearShipmentFilter1() {
                this.initShipmentFilters1();
                this.loadDataFromShipment(1);
            },
            loadMenuTree() {
                CommonJs.loadMenuTree('704', this.currentMenuId, this.$route, this
                    .loadData, this.setlistTitleFun, this.setloadMenuTree);
            },
            loadUserAuth() {
                var listwhere = {
                    pageindex: 1,
                    pagesize: 50,
                    conditions: [{
                        name: 'parentid',
                        value: '704',
                        operation: '='
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{usermoduleauthlist(where:" + JSON.stringify(listwhere) +
                    "){appid own authtype}}"
                ).then(res => {
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.usermoduleauth = jsonData.data.usermoduleauthlist;

                        var createinstorageflag = this.$route.query['createinstorage'];
                        if (createinstorageflag) {
                            this.createInStorage();
                        }
                    }
                });
            },
            setloadMenuTree(v_nodes, v_selectedKey, v_expandedKey, v_queryPlanObj, v_filters) {
                this.nodes = v_nodes;
                this.selectedKey = v_selectedKey;
                this.expandedKey = v_expandedKey;
                this.queryPlanObj = v_queryPlanObj;
                this.filters = v_filters;
            },
            onNodeSelect(node) {
                console.log(node);
                CommonJs.onNodeSelect(node, this.selectedKey, this.currentMenuId, '草稿列表', this.loadData, this
                    .setlistTitleFun, this.setsaveQueryPlan, this.setinitFilters, this.clearFilter1);
            },
            saveCustomFilter() {
                if (parseInt(this.queryPlanObj.id) >= 0) {
                    this.saveQueryPlan();
                } else {
                    this.displayQueryPlan = true;
                }
            },
            closeQueryPlan() {
                this.closeQueryPlanFun(false);
            },
            butloadinngFun(val) {
                this.butLoading = val;
            },
            closeQueryPlanFun(val) {
                this.displayQueryPlan = val;
            },
            saveQueryPlan() {
                CommonJs.saveQueryPlan(this.queryPlanObj, this.nodes, MessageTip, this.butloadinngFun, this
                    .closeQueryPlanFun, this.setsaveQueryPlan);
            },
            setsaveQueryPlan(v_queryPlanObj, v_nodes) {
                this.queryPlanObj = v_queryPlanObj;
                if (v_nodes)
                    this.nodes = v_nodes;
            },
            customFilter() {
                setTimeout(() => {
                    this.loadData(1);
                }, 10);
            },
            customShipmentFilter() {
                setTimeout(() => {
                    this.loadDataFromShipment(1);
                }, 10);
            },
            listPage(event) {
                var page = event.page;
                this.pagesize = event.rows;
                CommonJs.SaveDefaultPageSize(this.pagesize, this);
                var sortfield = event['sortField'];
                var sortorder = event['sortOrder'];
                if (sortfield && sortfield != '') {
                    if (sortorder == '-1') {
                        sortorder = "desc";
                    } else {
                        sortorder = "asc";
                    }
                } else {
                    sortfield = "";
                    sortorder = "";
                }
                this.loadData(page + 1, sortfield, sortorder);
            },
            onSort(event) {
                console.log('onSort...');
                console.log(event);
                var sortfield = event['sortField'];
                var sortorder = event['sortOrder'];
                if (sortorder == '-1') {
                    sortorder = "desc";
                } else {
                    sortorder = "asc";
                }
                this.loadData(1, sortfield, sortorder);
            },
            listShipmentPage(event) {
                var page = event.page;
                this.loadDataFromShipment(page + 1);
            },
            loadData(v_page, v_sortfield, v_sortorder) {
                this.loading = true;
                var listwhere = {
                    pageindex: v_page,
                    pagesize: this.pagesize,
                    conditions: [{
                        name: 'instoragedraftbox',
                        value: '',
                        operation: '='
                    }]
                };
                if (v_sortfield && v_sortorder) {
                    listwhere['sortfield'] = v_sortfield;
                    listwhere['sortorder'] = v_sortorder;
                }
                for (var key in this.filters1) {
                    if (key == 'global' && this.filters1[key]['value']) {
                        listwhere.conditions.push({
                            name: 'instorageorderno',
                            value: this.filters1[key]['value'],
                            operation: this.filters1[key]['matchMode']
                        });
                    }
                    if (this.filters1[key]['constraints']) {
                        for (var i = 0; i < this.filters1[key].constraints.length; i++) {
                            if (this.filters1[key].constraints[i].value && this.filters1[key].constraints[i].value !=
                                '') {
                                listwhere.conditions.push({
                                    name: key,
                                    value: this.filters1[key].constraints[i].value,
                                    operation: this.filters1[key].constraints[i].matchMode
                                });
                            }
                        }
                    }
                }
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{putinstoragelist(where:" + JSON.stringify(listwhere) +
                    "){id instorageorderno applydate portshipmentname saildate containerno containersize ispalletizingname expectedinstorage storagewarehousename storename isskushipmentname operatorname dtequationname foreigndtequationname instoragecurrencyname instorageexchangerate fobcurrencyname fobexchangerate receiptdate instoragestatusname flowid workflowid boxid stateid currentstatename }}"
                ).then(res => {
                    this.loading = false;
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.instoragelist = jsonData.data.putinstoragelist;
                        this.totalRecords = jsonData.page.allrecord;
                        this.first = this.pagesize * (jsonData.page.pageindex - 1);
                    }
                });
            },
            closeShipmentData() {
                this.selectedShipmentRow = null;
                this.showShipmentDialog = false;
            },
            importShipmentData() {
                if (!CommonJs.userOwerAppId(this.usermoduleauth, 'CreatePutInStorage')) {
                    MessageTip.warnmsg('请联系管理员开通新建入库权限');
                    return;
                }
                if (!this.selectedShipmentRow) {
                    MessageTip.warnmsg('请选择出运单');
                    return;
                }
                this.recordsubject = '新建入库';
                this.currentRow = {
                    bindpage: 'putinstorage',
                    id: '-1',
                    workflowid: '',
                    flowguid: 'PutInStorage',
                    boxid: '',
                    stateid: this.startStateId,
                    flowid: this.flowId,
                };
                this.showShipmentDialog = false;
                this.displayMaximizable = true;
            },
            loadDataAfter(v_mainelements, v_childelements, v_originalData, v_noshowelements,
                v_childExpandeConfig) {
                console.log('loadDataAfter...');
                if (this.currentRow && this.currentRow['id'] == '-1') {
                    console.log(v_mainelements);
                    console.log(v_childelements);
                    console.log(v_originalData);
                    console.log(v_noshowelements);
                    console.log(this.selectedShipmentRow);
                    if (this.selectedShipmentRow['storagewarehouse'] && this.selectedShipmentRow['storagewarehouse'] !=
                        '') {
                        v_originalData['putinstorage']['storagewarehouse'] = this.selectedShipmentRow[
                            'storagewarehouse'];
                    }
                    if (this.selectedShipmentRow['expectedinstorage'] && this.selectedShipmentRow[
                            'expectedinstorage'] != '') {
                        v_originalData['putinstorage']['expectedinstorage'] = this.selectedShipmentRow[
                            'expectedinstorage'];
                    }
                    v_originalData['putinstorage']['instoragecurrency'] = this.selectedShipmentRow['purchasecurrency'];
                    v_originalData['putinstorage']['fobcurrency'] = this.selectedShipmentRow['fobcurrency'];
                    v_originalData['putinstorage']['rateprofit'] = this.selectedShipmentRow['rateprofit'] + '';
                    v_originalData['putinstorage']['sharingcostcurrency'] = this.selectedShipmentRow[
                        'sharingcostcurrency'];
                    v_originalData['putinstorage']['sharingcostexchangerate'] = this.selectedShipmentRow[
                        'sharingcostexchangerate'] + '';
                    v_originalData['putinstorage']['purchasecurrency'] = this.selectedShipmentRow['purchasecurrency'];
                    v_originalData['putinstorage']['purchaseexchangerate'] = this.selectedShipmentRow[
                        'purchaseexchangerate'] + '';
                    v_originalData['putinstorage']['shipmentid'] = this.selectedShipmentRow['id'];
                    v_originalData['putinstorage']['storeid'] = this.selectedShipmentRow['storeid'];
                    v_noshowelements['storeid'] = this.selectedShipmentRow['storeid'];
                    v_noshowelements['fobcurrency'] = this.selectedShipmentRow['fobcurrency'];
                    v_noshowelements['rateprofit'] = this.selectedShipmentRow['rateprofit'] + '';
                    v_noshowelements['shipmentid'] = this.selectedShipmentRow['id'];
                    v_noshowelements['purchasecurrency'] = this.selectedShipmentRow['purchasecurrency'];
                    v_noshowelements['purchaseexchangerate'] = this.selectedShipmentRow['purchaseexchangerate'] + '';
                    setTimeout(() => {
                        this.$refs.universalpage.$refs.instoragecurrency.freshSelect(v_originalData[
                            'putinstorage'][
                            'instoragecurrency'
                        ]);
                        if (this.selectedShipmentRow['storagewarehouse'] && this.selectedShipmentRow[
                                'storagewarehouse'] != '') {
                            this.$refs.universalpage.$refs.storagewarehouse.freshSelect(v_originalData[
                                'putinstorage']['storagewarehouse']);
                        }
                    }, 100);
                    this.inStorageCurrencyChange(v_childelements, v_originalData['putinstorage']['instoragecurrency'],
                        this.currencyDict, v_childExpandeConfig);
                    this.fobcurrencyChange(v_childelements, v_originalData['putinstorage']['fobcurrency'], this
                        .currencyDict, v_childExpandeConfig);
                    for (var k = 0; k < v_mainelements.length; k++) {
                        if (v_mainelements[k]['name'] == 'storagewarehouse' && this.selectedShipmentRow[
                                'storagewarehouse'] && this.selectedShipmentRow['storagewarehouse'] != '') {
                            v_mainelements[k]['val'] = this.selectedShipmentRow['storagewarehouse'];
                        }
                        if (v_mainelements[k]['name'] == 'expectedinstorage' && this.selectedShipmentRow[
                                'expectedinstorage'] && this.selectedShipmentRow['expectedinstorage'] != '') {
                            v_mainelements[k]['val'] = this.selectedShipmentRow['expectedinstorage'];
                        }
                        if (v_mainelements[k]['name'] == 'instorageorderno') {
                            v_mainelements[k]['val'] = this.selectedShipmentRow['shipmentno'];
                        }
                        if (v_mainelements[k]['name'] == 'storeid') {
                            v_mainelements[k]['val'] = this.selectedShipmentRow['storeid'];
                        }
                        if (v_mainelements[k]['name'] == 'storename') {
                            v_mainelements[k]['val'] = this.selectedShipmentRow['storename'];
                        }
                        if (v_mainelements[k]['name'] == 'fobcurrencyname') {
                            v_mainelements[k]['val'] = this.selectedShipmentRow['fobcurrencyname'];
                        }
                        if (v_mainelements[k]['name'] == 'fobexchangerate') {
                            v_mainelements[k]['val'] = this.selectedShipmentRow['fobexchangerate'];
                        }
                        if (v_mainelements[k]['name'] == 'instorageexchangerate') {
                            v_mainelements[k]['val'] = this.selectedShipmentRow['purchaseexchangerate'];
                        }
                        if (v_mainelements[k]['name'] == 'sharingcostcurrency') {
                            v_mainelements[k]['val'] = this.selectedShipmentRow['sharingcostcurrency'];
                        }
                        if (v_mainelements[k]['name'] == 'sharingcostexchangerate') {
                            v_mainelements[k]['val'] = this.selectedShipmentRow['sharingcostexchangerate'];
                        }
                    }
                    for (var k2 = 0; k2 < v_childelements.length; k2++) {
                        if (v_childelements[k2]['name'] == 'products') {
                            v_childelements[k2]['rows'] = [];
                            var expectedinstoragequantitysum = 0;
                            //var actualinstoragequantitysum = 0;
                            var differencesum = 0;
                            var allvolumesum = 0;
                            for (var i = 0; i < this.selectedShipmentRow['products'].length; i++) {
                                var shipmentProductObj = this.selectedShipmentRow['products'][i];
                                var productObj = {
                                    id: '-' + shipmentProductObj['id'],
                                    shipmentid: this.selectedShipmentRow['id'],
                                    productid: shipmentProductObj['productid'] + '',
                                    producttype: shipmentProductObj['producttype'],
                                    shipmentproductid: shipmentProductObj['id'],
                                    platformsku: shipmentProductObj['sku'],
                                    platformmpn: shipmentProductObj['mpn'],
                                    name: shipmentProductObj['productname'],
                                    expectedinstoragequantity: shipmentProductObj['quantity'],
                                    actualinstoragequantity: 0,
                                    unitprice: shipmentProductObj['unitprice'],
                                    fobprice: shipmentProductObj['fobprice'],
                                    difference: 0,
                                    long: shipmentProductObj['length'],
                                    width: shipmentProductObj['width'],
                                    high: shipmentProductObj['height'],
                                    weight: shipmentProductObj['grossweight'],
                                    volume: shipmentProductObj['volume'],
                                    url: shipmentProductObj['url'],
                                    allvolume: 0,
                                    sharingcost: 0,
                                    alllweight: 0,
                                    parts: []
                                };

                                for (var i2 = 0; i2 < shipmentProductObj['parts'].length; i2++) {
                                    var partsObj = {
                                        id: '-' + shipmentProductObj['parts'][i2]['id'],
                                        matching: shipmentProductObj['parts'][i2]['matching'],
                                        productpartsid: shipmentProductObj['parts'][i2]['productpartsid'],
                                        tblputinstorageid: '',
                                        shipmentid: shipmentProductObj['parts'][i2]['tblshipmentid'] + '',
                                        url: shipmentProductObj['parts'][i2]['url'],
                                        productid: shipmentProductObj['parts'][i2]['productid'] + '',
                                        shipmentproductid: shipmentProductObj['parts'][i2]['parentid'] + '',
                                        platformsku: shipmentProductObj['parts'][i2]['sku'],
                                        platformmpn: shipmentProductObj['parts'][i2]['mpn'],
                                        name: shipmentProductObj['parts'][i2]['productname'],
                                        expectedinstoragequantity: shipmentProductObj['parts'][i2]['quantity'],
                                        actualinstoragequantity: 0,
                                        difference: 0,
                                        fobprice: shipmentProductObj['parts'][i2]['fobprice'],
                                        long: shipmentProductObj['parts'][i2]['length'],
                                        width: shipmentProductObj['parts'][i2]['width'],
                                        high: shipmentProductObj['parts'][i2]['height'],
                                        weight: shipmentProductObj['parts'][i2]['grossweight'],
                                        volume: shipmentProductObj['parts'][i2]['volume'],
                                        unitprice: shipmentProductObj['parts'][i2]['unitprice'],
                                        allvolume: 0,
                                        sharingcost: 0,
                                        alllweight: 0,
                                        isonebox: 0,
                                    };
                                    var volumetempnum2 = Number(partsObj.volume);
                                    var expectedinstoragequantity2 = Number(partsObj.expectedinstoragequantity);
                                    if (!isNaN(expectedinstoragequantity2) && !isNaN(volumetempnum2)) {
                                        partsObj.allvolume = (expectedinstoragequantity2 * volumetempnum2).toFixed(2);
                                    }
                                    productObj['parts'].push(partsObj);
                                }
                                var tempnum = Number(productObj.expectedinstoragequantity);
                                if (!isNaN(tempnum)) {
                                    expectedinstoragequantitysum = expectedinstoragequantitysum + tempnum;
                                }
                                var volumetempnum = Number(productObj.volume);
                                if (!isNaN(tempnum) && !isNaN(volumetempnum)) {
                                    productObj.allvolume = (tempnum * volumetempnum).toFixed(2);
                                    allvolumesum = allvolumesum + (tempnum * volumetempnum);
                                }
                                tempnum = Number(productObj.difference);
                                if (!isNaN(tempnum)) {
                                    differencesum = differencesum + tempnum;
                                }
                                v_childelements[k2]['rows'].push(productObj);
                            }
                            this.childTableFooter['products'].expectedinstoragequantity = expectedinstoragequantitysum
                                .toFixed(2);
                            this.childTableFooter['products'].difference = differencesum.toFixed(2);
                            this.childTableFooter['products'].alllweight = 0;
                            this.childTableFooter['products'].allvolume = 0;
                        }
                        if (v_childelements[k2]['name'] == 'cost') {
                            v_childelements[k2]['rows'] = [];
                        }
                    }
                } else {
                    for (var k3 = 0; k3 < v_mainelements.length; k3++) {
                        if (v_mainelements[k3]['name'] == 'instoragecurrency') {
                            this.inStorageCurrencyChange(v_childelements, v_mainelements[k3]['val'], this.currencyDict,
                                v_childExpandeConfig);
                        }
                    }
                    for (var pro in v_originalData.putinstorage) {
                        if (pro == 'fobcurrency') {
                            this.fobcurrencyChange(v_childelements, v_originalData.putinstorage[pro], this
                                .currencyDict, v_childExpandeConfig);
                            break;
                        }
                    }
                }
            },
            createInStorage() {
                if (!CommonJs.userOwerAppId(this.usermoduleauth, 'CreatePutInStorage')) {
                    MessageTip.warnmsg('请联系管理员开通新建入库权限');
                    return;
                }
                this.selectedShipmentRow = null;
                this.shipments = [];
                this.initShipmentFilters1();
                this.loadDataFromShipment(1);
                this.showShipmentDialog = true;
            },
            delInStorage() {
                this.$confirm.require({
                    message: '确认要删除?',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => {
                        if (!this.selectedRow || this.selectedRow.length == 0) {
                            MessageTip.warnmsg('请选择要删除入库单');
                            return;
                        }
                        var instorageIds = [];
                        for (var k1 = 0; k1 < this.selectedRow.length; k1++) {
                            instorageIds.push({
                                "id": this.selectedRow[k1]["id"]
                            });
                        }
                        requests.graphql_requests.post(
                            '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                            "delete{putinstoragelist(o:" + JSON.stringify(instorageIds) +
                            "){id errmsg}}"
                        ).then(res => {
                            if (res.errcode == "0") {
                                this.selectedRow = null;
                                this.loadData(1);
                                MessageTip.successmsg('删除成功');
                            } else {
                                MessageTip.warnmsg('删除失败');
                            }
                        });
                    },
                    reject: () => {}
                });
            },
            loadDataFromShipment(v_page) {
                var listwhere = {
                    pageindex: v_page,
                    pagesize: 50,
                    conditions: [{
                        name: 'instorageimport',
                        value: '',
                        operation: '='
                    }, {
                        name: 'storeid',
                        value: sessionStorage.getItem("storeid"),
                        operation: '='
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{shipmentlist(where:" + JSON.stringify(listwhere) +
                    "){id shipmentno storagewarehouse expectedinstorage signingdate shippingway shippingwayname purchasecurrency purchasecurrencyname purchaseexchangerate fobcurrency fobcurrencyname fobexchangerate rateprofit sharingcostcurrency sharingcostexchangerate instoragename approvestatus storename storeid approvestatusname operator " +
                    "cost {id costtype costtypename costamount currency currencyname exchangerate invoiceamount taxrate} products {id productid producttype productname mpn sku colorname quantity unitname unitprice volume length width height grossweight sharingcost taxrebates fobprice url " +
                    "parts {id matching productpartsid purchaseid tblshipmentid factoryname purchaseno mpn sku productname url productid parentid size colorname color quantity unit unitname unitprice volume sharingcost taxrebates fobprice length width height grossweight netweight} }" +
                    "}}"
                ).then(res => {
                    this.loading = false;
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.shipments = jsonData.data.shipmentlist;
                        this.totalShipmentRecords = jsonData.page.allrecord;
                        this.firstShipment = 50 * (jsonData.page.pageindex - 1);
                    }
                });
            },
            openInStorage(v_data) {
                this.currentState = '流程信息-当前环节:' + v_data.currentstatename;
                this.recordsubject = '入库单' + v_data.instorageorderno;
                this.currentRow = {
                    bindpage: 'putinstorage',
                    id: v_data.id,
                    workflowid: v_data.workflowid,
                    flowguid: 'PutInStorage',
                    boxid: v_data.boxid,
                    stateid: this.startStateId,
                    flowid: this.flowId,
                };
                this.displayMaximizable = true;
            },
            importAddQueryCondition(v_listwhere) {
                v_listwhere.conditions.push({
                    name: 'shiptoinstorage',
                    value: '',
                    operation: '=',
                });
                return v_listwhere;
            },
            importAddCols(v_cols, v_graphqlFeild) {
                console.log('importAddCols:1');
                console.log(v_cols);
                v_graphqlFeild = v_graphqlFeild + ' factoryname purchaseno ';
                v_cols.unshift({
                    name: 'shipmentno',
                    label: '入库单号',
                    type: 'LabelString',
                    dictName: '',
                    dictLabel: '',
                    dictCode: '',
                    dictCode2: '',
                    dictCode3: '',
                });
                console.log('importAddCols:2');
                console.log(v_cols);
                return v_graphqlFeild;
            },
            closeMaximizable() {
                this.displayMaximizable = false;
            },
            freshList() {
                this.butLoading = false;
                this.loadData(1);
            },
            saveData() {
                this.butLoading = true;
                this.freshcalculate();
                this.$refs.universalpage.saveInfo(true);
            },
            submitData() {
                this.butLoading = true;
                this.freshcalculate();
                this.$refs.universalpage.saveInfo();
            },
            closeButLoading(val) {
                if (val) {
                    this.butLoading = true;
                } else {
                    this.butLoading = false;
                }
            },
            openLeftPosition() {
                console.log('recordsubject:' + this.recordsubject);
                this.flowhistoryJson = this.$refs.universalpage.flowhistory;
                this.displayPosition = true;
            },
            closePosition() {
                this.displayPosition = false;
            },
            getAllCost(costdata) {
                var allcost = 0;
                if (costdata) {
                    for (var k = 0; k < costdata.length; k++) {
                        var costamount = Number(costdata[k]['costamount']);
                        //var exchangerate = Number(costdata[k]['exchangerate']);
                        if (!isNaN(costamount)) {
                            allcost = allcost + costamount;
                        }
                    }
                }
                return allcost;
            },
            getAllVolume(productsdata) {
                var volumesummary = 0;
                for (var k2 = 0; k2 < productsdata.length; k2++) {
                    var tempnum2 = Number(productsdata[k2]['volume']);
                    var quantitynum2 = Number(productsdata[k2]['actualinstoragequantity']);
                    if (!isNaN(tempnum2) && !isNaN(quantitynum2)) {
                        volumesummary = volumesummary + tempnum2 * quantitynum2;
                    }
                }
                return volumesummary;
            },
            childCreateBefore(v_childname, v_newRow) {
                console.log('childCreateBefore....');
                console.log(v_childname);
                console.log(v_newRow);
                if (v_childname == 'cost') {
                    var allelements = this.$refs.universalpage.getAllMainElements();
                    var noshowelements = this.$refs.universalpage.getNoShowElements();
                    console.log(allelements);
                    console.log(noshowelements);
                    var inStorageExchangeRate = 0;
                    var inStorageCurrency = -1;
                    for (var k1 = 0; k1 < allelements.length; k1++) {
                        if (allelements[k1]['name'] == 'instorageexchangerate') {
                            inStorageExchangeRate = Number(allelements[k1]['val']);
                        }
                        if (allelements[k1]['name'] == 'instoragecurrency') {
                            inStorageCurrency = Number(allelements[k1]['val']);
                        }
                    }
                    if (noshowelements['instoragecurrencyname']) {
                        v_newRow['currencyname'] = noshowelements['instoragecurrencyname'];
                    }
                    v_newRow['currency'] = inStorageCurrency + "";
                    v_newRow['exchangerate'] = inStorageExchangeRate;
                }
                return v_newRow;
            },
            cellEditCompleteExpande(tablename, field, currentrow, parentRow, allcurrentdata, allparentdata,
                childTableFooterObj, v_elements, v_alonechildelements, v_childelements) {
                console.log('cellEditCompleteExpandeMethod..');
                console.log(tablename);
                console.log(field);
                console.log(currentrow);
                console.log(parentRow);
                console.log(allcurrentdata);
                console.log(allparentdata);
                console.log(childTableFooterObj);
                console.log(v_elements);
                console.log(v_alonechildelements);
                console.log(v_childelements);
            },
            calculateCostProductPart(productsdata, inStorageExchangeRate, fOBExchangeRate) {
                console.log('calculateCostProductPart..');
                console.log(productsdata);
                console.log(inStorageExchangeRate);
                console.log(fOBExchangeRate);
                console.log('productsdata.length....');
                console.log(productsdata.length);
                for (var k = 0; k < productsdata.length; k++) {
                    console.log('productsdata[k][parts]....');
                    console.log(productsdata[k]['parts']);
                    if (productsdata[k]['parts']) {
                        var volumenum = Number(productsdata[k]['volume']);
                        var sharingcostnum = Number(productsdata[k]['sharingcost']);
                        var instoragepricenum = Number(productsdata[k]['instorageprice']);
                        console.log(volumenum);
                        console.log(sharingcostnum);
                        console.log(instoragepricenum);
                        if (!isNaN(volumenum) && !isNaN(inStorageExchangeRate) && inStorageExchangeRate > 0 && !isNaN(
                                sharingcostnum)) {
                            var sharingcostnum1 = 0;
                            for (var k1 = 0; k1 < productsdata[k]['parts'].length; k1++) {
                                productsdata[k]['parts'][k1]['sharingcost'] = 0;
                                productsdata[k]['parts'][k1]['instorageprice'] = 0;
                                var volumenum1 = Number(productsdata[k]['parts'][k1]['volume']);
                                var matchingnum1 = Number(productsdata[k]['parts'][k1]['matching']);
                                var quantitynum = Number(productsdata[k]['parts'][k1]['actualinstoragequantity']);
                                if (productsdata[k]['parts'].length == 1) {
                                    productsdata[k]['parts'][k1]['sharingcost'] = (sharingcostnum / matchingnum1)
                                        .toFixed(2);
                                    if (!isNaN(instoragepricenum)) {
                                        productsdata[k]['parts'][k1]['instorageprice'] = (instoragepricenum /
                                            matchingnum1).toFixed(2);
                                    }
                                } else {
                                    if (k1 != productsdata[k]['parts'].length - 1) {
                                        if (!isNaN(volumenum1) && !isNaN(matchingnum1) && !isNaN(sharingcostnum)) {
                                            var sharingcostnum2 = (sharingcostnum * ((volumenum1 *
                                                matchingnum1) / volumenum)).toFixed(2);
                                            productsdata[k]['parts'][k1]['sharingcost'] = sharingcostnum2;
                                            sharingcostnum1 = sharingcostnum1 + Number(sharingcostnum2);
                                        }
                                    } else {
                                        console.log('最后一行...');
                                        console.log(sharingcostnum);
                                        console.log(sharingcostnum1);
                                        productsdata[k]['parts'][k1]['sharingcost'] = (sharingcostnum - sharingcostnum1)
                                            .toFixed(2);
                                    }
                                    var currentsharingcostnum = Number(productsdata[k]['parts'][k1]['sharingcost']);
                                    var currentfobpricenum = Number(productsdata[k]['parts'][k1]['fobprice']);
                                    if (isNaN(currentfobpricenum)) {
                                        currentfobpricenum = 0;
                                    }
                                    if (!isNaN(quantitynum) && quantitynum > 0 && !isNaN(currentfobpricenum) && !isNaN(
                                            currentsharingcostnum) && currentsharingcostnum > 0) {
                                        console.log("(" + currentfobpricenum + "*" + fOBExchangeRate + "/" +
                                            inStorageExchangeRate + "+" + currentsharingcostnum + "/" +
                                            quantitynum + ")");
                                        var instoragepricenum2 = (currentfobpricenum * fOBExchangeRate /
                                            inStorageExchangeRate + currentsharingcostnum / quantitynum).toFixed(2);
                                        if (k1 != productsdata[k]['parts'].length - 1) {
                                            productsdata[k]['parts'][k1]['instorageprice'] = instoragepricenum2;
                                            if (!isNaN(instoragepricenum))
                                                instoragepricenum = instoragepricenum - Number(instoragepricenum2);
                                        } else {
                                            if (!isNaN(instoragepricenum)) {
                                                productsdata[k]['parts'][k1]['instorageprice'] = instoragepricenum;
                                            } else {
                                                productsdata[k]['parts'][k1]['instorageprice'] = instoragepricenum2;
                                            }
                                        }
                                    }
                                }
                            }
                            console.log(productsdata[k]);
                        }
                    }
                }
            },
            cellEditComplete(tablename, field, data, rowdata, childTableFooterObj, v_childelements,
                v_alonechildelements, v_elements, v_noshowelements) {
                console.log('cellEditCompleteMethod..');
                console.log(field);
                console.log(data);
                console.log(rowdata);
                console.log(childTableFooterObj);
                console.log(v_childelements);
                console.log(v_alonechildelements);
                console.log(v_elements);
                if (tablename != 'products' && tablename != 'cost') {
                    return;
                }
                var productsdata = [];
                var inStorageExchangeRate = 0;
                var fOBExchangeRate = 0;
                for (var k1 = 0; k1 < v_elements.length; k1++) {
                    if (v_elements[k1]['name'] == 'instorageexchangerate') {
                        inStorageExchangeRate = Number(v_elements[k1]['val']);
                    }
                    if (v_elements[k1]['name'] == 'fobexchangerate') {
                        fOBExchangeRate = Number(v_elements[k1]['val']);
                    }
                }
                console.log('noshowElements...');
                console.log(v_noshowelements);
                for (var i = 0; i < v_childelements.length; i++) {
                    if (v_childelements[i]['name'] == 'products') {
                        productsdata = v_childelements[i]['rows'];
                        break;
                    }
                }
                if (tablename == 'products' && field == 'actualinstoragequantity' && rowdata['parts']) {
                    var actualinstoragequantitynum = Number(rowdata['actualinstoragequantity']);
                    if (!isNaN(actualinstoragequantitynum)) {
                        for (var i1 = 0; i1 < rowdata['parts'].length; i1++) {
                            var matchingnum1 = Number(rowdata['parts'][i1]['matching']);
                            if (!isNaN(matchingnum1)) {
                                rowdata['parts'][i1]['actualinstoragequantity'] = matchingnum1 *
                                    actualinstoragequantitynum;
                            }
                        }
                    }
                }
                if (tablename == 'products') {
                    this.productsvolumecalculate(rowdata);
                    this.productsweightcalculate(rowdata);
                    this.productsdifferencecalculate(rowdata);
                }
                if (tablename == 'cost') {
                    this.costcalculate(rowdata);
                }
                var volumesummary = this.getAllVolume(productsdata);
                var costdata;
                for (var i2 = 0; i2 < v_childelements.length; i2++) {
                    if (v_childelements[i2]['name'] == 'cost') {
                        costdata = v_childelements[i2]['rows'];
                        break;
                    }
                }
                console.log('costdata....');
                console.log(costdata);
                var allcost = this.getAllCost(costdata);
                var sharingcostsum = 0;
                for (var k4 = 0; k4 < productsdata.length; k4++) {
                    if (k4 == productsdata.length - 1) {
                        productsdata[k4]['sharingcost'] = (allcost - sharingcostsum).toFixed(2);
                    } else {
                        this.productssharingcostcalculate(productsdata[k4], volumesummary, allcost);
                        sharingcostsum = sharingcostsum + Number(productsdata[k4]['sharingcost']);
                    }
                    this.productsfobpricecalculate(productsdata[k4], inStorageExchangeRate, fOBExchangeRate);
                }
                this.calculateCostProductPart(productsdata, inStorageExchangeRate, fOBExchangeRate);
            },
            freshcalculate() {
                var allelements = this.$refs.universalpage.getAllMainElements();
                var allchildelements = this.$refs.universalpage.getAllChildElements();
                var productsdata = [];
                var inStorageExchangeRate = 0;
                var fOBExchangeRate = 0;
                for (var k1 = 0; k1 < allelements.length; k1++) {
                    if (allelements[k1]['name'] == 'instorageexchangerate') {
                        inStorageExchangeRate = Number(allelements[k1]['val']);
                    }
                    if (allelements[k1]['name'] == 'fobexchangerate') {
                        fOBExchangeRate = Number(allelements[k1]['val']);
                    }
                }
                for (var i = 0; i < allchildelements.length; i++) {
                    if (allchildelements[i]['name'] == 'products') {
                        productsdata = allchildelements[i]['rows'];
                        break;
                    }
                }
                var volumesummary = this.getAllVolume(productsdata);
                var costdata;
                for (var i2 = 0; i2 < allchildelements.length; i2++) {
                    if (allchildelements[i2]['name'] == 'cost') {
                        costdata = allchildelements[i2]['rows'];
                        break;
                    }
                }
                console.log('costdata....');
                console.log(costdata);
                var allcost = this.getAllCost(costdata);
                var sharingcostsum = 0;
                for (var k4 = 0; k4 < productsdata.length; k4++) {
                    if (k4 == productsdata.length - 1) {
                        productsdata[k4]['sharingcost'] = (allcost - sharingcostsum).toFixed(2);
                    } else {
                        this.productssharingcostcalculate(productsdata[k4], volumesummary, allcost);
                        sharingcostsum = sharingcostsum + Number(productsdata[k4]['sharingcost']);
                    }
                    this.productsfobpricecalculate(productsdata[k4], inStorageExchangeRate, fOBExchangeRate);
                }
                this.calculateCostProductPart(productsdata, inStorageExchangeRate, fOBExchangeRate);
                for (var n2 = 0; n2 < allchildelements.length; n2++) {
                    this.$refs.universalpage.sumAllField(allchildelements[n2]['name'], allchildelements[n2]['rows']);
                }
            },
            productsdifferencecalculate(rowdata) {
                var expectedinstoragequantitynum = Number(rowdata['expectedinstoragequantity']);
                var actualinstoragequantitynum = Number(rowdata['actualinstoragequantity']);
                if (!isNaN(expectedinstoragequantitynum) && !isNaN(actualinstoragequantitynum)) {
                    rowdata['difference'] = actualinstoragequantitynum - expectedinstoragequantitynum;
                }
            },
            costcalculate(rowdata) {
                var taxratenum = Number(rowdata['taxrate']);
                var invoiceamountnum = Number(rowdata['invoiceamount']);
                if (!isNaN(taxratenum) && !isNaN(invoiceamountnum)) {
                    rowdata['costamount'] = invoiceamountnum * (1 - taxratenum).toFixed(2);
                }
                console.log('costcalculate..' + rowdata['costamount']);
            },
            productsvolumecalculate(rowdata) {
                var volumenum = Number(rowdata['volume']);
                var quantitynum = Number(rowdata['actualinstoragequantity']);
                if (!isNaN(quantitynum) && !isNaN(volumenum))
                    rowdata['allvolume'] = (quantitynum * volumenum).toFixed(4);
                console.log('productsvolumecalculate..' + rowdata['allvolume']);
            },
            productsweightcalculate(rowdata) {
                var weightnum = Number(rowdata['weight']);
                var quantitynum = Number(rowdata['actualinstoragequantity']);
                if (!isNaN(quantitynum) && !isNaN(weightnum))
                    rowdata['allweight'] = (quantitynum * weightnum).toFixed(2);
                console.log('productsweightcalculate..' + rowdata['allweight']);
            },
            productssharingcostcalculate(rowdata, volumesummary, allcost) {
                console.log('productssharingcostcalculate..1..');
                console.log(rowdata);
                console.log(volumesummary);
                console.log(allcost);
                rowdata['sharingcost'] = 0;
                var volume = Number(rowdata['volume']);
                var quantitynum = Number(rowdata['actualinstoragequantity']);
                if (!isNaN(volume) && !isNaN(quantitynum) && !isNaN(allcost)) {
                    if (quantitynum > 0 && volumesummary > 0 && allcost > 0) {
                        console.log("(((" + volume + "*" + quantitynum + ")/" + volumesummary + ")*" + allcost + ")");
                        rowdata['sharingcost'] = (((volume * quantitynum) / volumesummary) * allcost).toFixed(2);
                    }
                }
                console.log('productssharingcostcalculate..' + rowdata['sharingcost']);
            },
            productsfobpricecalculate(rowdata, inStorageExchangeRate, fOBExchangeRate) {
                var quantitynum = Number(rowdata['actualinstoragequantity']);
                var sharingcost = Number(rowdata['sharingcost']);
                var fobprice = Number(rowdata['fobprice']);
                console.log('quantitynum..' + quantitynum);
                console.log('sharingcost..' + sharingcost);
                console.log('inStorageExchangeRate..' + inStorageExchangeRate);
                console.log('fOBExchangeRate..' + fOBExchangeRate);
                rowdata['instorageprice'] = 0;
                if (isNaN(fobprice)) {
                    fobprice = 0;
                }
                if (!isNaN(sharingcost) && !isNaN(quantitynum) && !isNaN(inStorageExchangeRate) && !isNaN(
                        fOBExchangeRate) && quantitynum > 0) {
                    console.log("(" + fobprice + "*" + fOBExchangeRate + "/" + inStorageExchangeRate + "+" +
                        sharingcost +
                        "/" + quantitynum + ")");
                    rowdata['instorageprice'] = (fobprice * fOBExchangeRate / inStorageExchangeRate + sharingcost /
                        quantitynum).toFixed(2);
                }
                console.log('productsfobpricecalculate..' + rowdata['instorageprice']);
            },
            loadFlowInfo() {
                requests.plat_requests.post(
                    '/calf/plat/FlowInfo?appkey=' + sessionStorage.getItem(
                        "appkey"), {
                        FlowGuid: 'PutInStorage',
                        BoxId: '',
                    }).then(res => {
                    console.log('loadFlowInfo...');
                    console.log(res);
                    if (res.errcode == "0") {
                        var retObj = JSON.parse(unescape(Base64.decode(res.data)));
                        console.log(retObj);
                        this.flowId = retObj.FlowId;
                        this.startStateId = retObj.CurrentStateId;
                    }
                });
            },
            fobcurrencyChange(v_childelements, v_val, v_dictsource, v_childExpandeConfig) {
                var ename = '';
                for (var i3 = 0; i3 < v_dictsource.length; i3++) {
                    if (v_dictsource[i3].code == v_val) {
                        ename = v_dictsource[i3].ename;
                        break;
                    }
                }
                var isbreak = false;
                for (var i = 0; i < v_childelements.length; i++) {
                    for (var i2 = 0; i2 < v_childelements[i].columns.length; i2++) {
                        if (v_childelements[i].columns[i2].field == 'fobprice') {
                            v_childelements[i].columns[i2].header = 'FOB单价(' + ename + ')';
                            isbreak = true;
                            break;
                        }
                    }
                    if (isbreak) {
                        break;
                    }
                }
                if (v_childExpandeConfig) {
                    for (var i4 = 0; i4 < v_childExpandeConfig.products.config[0].columns.length; i4++) {
                        if (v_childExpandeConfig.products.config[0].columns[i4].field == 'fobprice') {
                            v_childExpandeConfig.products.config[0].columns[i4].header = 'FOB单价(' + ename + ')';
                            break;
                        }
                    }
                } else {
                    for (var i5 = 0; i5 < this.childExpandeConfig.products.config[0].columns.length; i5++) {
                        if (this.childExpandeConfig.products.config[0].columns[i5].field == 'fobprice') {
                            this.childExpandeConfig.products.config[0].columns[i5].header = 'FOB单价(' + ename + ')';
                            break;
                        }
                    }
                    this.$refs.universalpage.setchildExpandeConfig(this.childExpandeConfig);
                }
                this.freshcalculate();
            },
            inStorageCurrencyChange(v_childelements, v_val, v_dictsource, v_childExpandeConfig) {
                var ename = '';
                for (var i3 = 0; i3 < v_dictsource.length; i3++) {
                    if (v_dictsource[i3].code == v_val) {
                        ename = v_dictsource[i3].ename;
                        break;
                    }
                }
                var isbreak = false;
                var loopindex = 0;
                for (var i = 0; i < v_childelements.length; i++) {
                    for (var i2 = 0; i2 < v_childelements[i].columns.length; i2++) {
                        if (v_childelements[i].columns[i2].field == 'unitprice') {
                            v_childelements[i].columns[i2].header = '产品单价(' + ename + ')';
                            loopindex++;
                        }
                        if (v_childelements[i].columns[i2].field == 'instorageprice') {
                            v_childelements[i].columns[i2].header = '入库单价(' + ename + ')';
                            loopindex++;
                        }
                        if (v_childelements[i].columns[i2].field == 'sharingcost') {
                            v_childelements[i].columns[i2].header = '分摊费用(' + ename + ')';
                            loopindex++;
                        }
                        if (loopindex == 3) {
                            isbreak = true;
                            break;
                        }
                    }
                    if (isbreak) {
                        break;
                    }
                }
                if (v_childExpandeConfig) {
                    for (var i4 = 0; i4 < v_childExpandeConfig.products.config[0].columns.length; i4++) {
                        if (v_childExpandeConfig.products.config[0].columns[i4].field == 'unitprice') {
                            v_childExpandeConfig.products.config[0].columns[i4].header = '产品单价(' + ename + ')';
                        }
                        if (v_childExpandeConfig.products.config[0].columns[i4].field == 'instorageprice') {
                            v_childExpandeConfig.products.config[0].columns[i4].header = '入库单价(' + ename + ')';
                        }
                        if (v_childExpandeConfig.products.config[0].columns[i4].field == 'sharingcost') {
                            v_childExpandeConfig.products.config[0].columns[i4].header = '分摊费用(' + ename + ')';
                        }
                    }

                } else {
                    for (var i5 = 0; i5 < this.childExpandeConfig.products.config[0].columns.length; i5++) {
                        if (this.childExpandeConfig.products.config[0].columns[i5].field == 'unitprice') {
                            this.childExpandeConfig.products.config[0].columns[i5].header = '产品单价(' + ename + ')';
                        }
                        if (this.childExpandeConfig.products.config[0].columns[i5].field == 'instorageprice') {
                            this.childExpandeConfig.products.config[0].columns[i5].header = '入库单价(' + ename + ')';
                        }
                        if (this.childExpandeConfig.products.config[0].columns[i5].field == 'sharingcost') {
                            this.childExpandeConfig.products.config[0].columns[i5].header = '分摊费用(' + ename + ')';
                        }
                    }
                    this.$refs.universalpage.setchildExpandeConfig(this.childExpandeConfig);
                }
                this.freshcalculate();
            },
            dialogmsgClose() {
                MessageTip.onClose();
            },
            selfmaximize() {
                CommonJs.selfmaximize();
            },
            dialogClose() {
                this.butLoading = false;
            }
        },
        components: {
            NewUniversalPage,
            FlowHistory,
            'AppFooter': AppFooter,
        }
    }
</script>